export const GET_GPS = 'GET_GPS';
export const GET_GPS_ERROR = 'GET_GPS_ERROR';
export const PAN_TRIGGER = 'PAN_TRIGGER';
export const GET_STORE = 'GET_STORE';
export const GET_LOCATION = 'GET_LOCATION';
export const PAN_TRIGGER1 = 'PAN_TRIGGER1';
export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_SELECTED_PERSONS = 'SET_SELECTED_PERSONS';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const LOADING = 'LOADING';
export const GET_REVIEW = 'GET_REVIEW';
export const MAKE_APPT = 'MAKE_APPT';
export const MAKE_APPT_ERROR = 'MAKE_APPT_ERROR';
export const CONTACT_FORM = 'CONTACT_FORM';
export const CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR';
export const SIGNUP_MESSAGE_RESET = 'SIGNUP_MESSAGE_RESET';
export const GET_ALL_STORE_FLAG = 'GET_ALL_STORE_FLAG';
export const LOGIN_VERIFY = 'LOGIN_VERIFY';
export const RESEND_VER = 'RESEND_VER';
export const LOGIN_MESSAGE_RESET = 'LOGIN_MESSAGE_RESET';
export const RESEND_VER_FAIL = 'RESEND_VER_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_APPOINTMENT = 'RESET_APPOINTMENT';
export const GET_REVIEW_ERROR = 'GET_REVIEW_ERROR';
export const SET_MID_LOCATION = 'SET_MID_LOCATION';
export const CHANGE_EMAIL = "CHANGE_EMAil";
export const Search = "Search";
export const onChange = "onChange";
export const SET_SELECTED_STARS = "SET_SELECTED_STARS";
export const LOGOUT_POPUP = "LOGOUT_POPUP";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";
export const ADD_ADDRESS_ERR_RESET = "ADD_ADDRESS_ERR_RESET";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const ADDRESS_DEL = "ADDRESS_DEL";
export const SELECT_FILTER = "SELECT_FILTER";
