export const months = {
  Jan: ["January", 0],
  Feb: ["February", 1],
  Mar: ["March", 2],
  Apr:["April",3],
  May:["May",4],
  Jun:["June",5],
  Jul:["July",6],
  Aug:["August",7],
  Sep:["September",8],
  Oct:["October",9],
  Nov:["November",10],
  Dec:["December",11]
};
